import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'
import { getEndOfTheDayFromTimestamp, getStartOfTheDayFromTimestamp } from '@/tools/util'

const lineChartsTypes = {
  users: 'users',
  fee: 'fee',
  products: 'products',
}

const productsFeesTypes = [
  'swap',
  'p2p',
  'exchange',
]

const comboChartsTypes = {
  quantityVolume: 'quantityVolume',
  volumeFee: 'volumeFee',
  activeUsers: 'activeUsers',
}

const state = () => ({
  total: null,
  map: null,
  volumeFeeSnapshot: null,
  lineCharts: {
    [lineChartsTypes.users]: [],
    [lineChartsTypes.fee]: [],
    [lineChartsTypes.products]: [],
  },
  comboCharts: {
    [comboChartsTypes.quantityVolume]: [],
    [comboChartsTypes.volumeFee]: [],
    [comboChartsTypes.activeUsers]: [],

  },
  statsToday: null,
  statsTotal: null,
  statsFees: null,
  volumeQuantityToday: null,
  volumeQuantityTotal: null,
  pairsList: [],
  pairsVolumeToday: [],
  pairsVolumeTotal: [],
  pairsVolume: null,
  pairsQuantity: null,
  productsFees: null,
  productsFeesTypes,
  deFiWalletsTypes: null,
  usersMap: null,
  holdInfo: {},
})

const getters = {
  total: state => state.total,
  map: state => state.map,
  volumeFeeSnapshot: state => state.volumeFeeSnapshot,
  lineCharts: state => state.lineCharts,
  comboCharts: state => state.comboCharts,
  statsToday: state => state.statsToday,
  statsTotal: state => state.statsTotal,
  statsFees: state => state.statsFees,
  volumeQuantityToday: state => state.volumeQuantityToday,
  volumeQuantityTotal: state => state.volumeQuantityTotal,
  pairsList: state => state.pairsList,
  pairsListLabels: state => state.pairsList?.map(pair => `${pair.from.symbol}-${pair.to.symbol} (${pair.from.network}-${pair.to.network})`),
  pairsVolumeToday: state => state.pairsVolumeToday,
  pairsVolumeTotal: state => state.pairsVolumeTotal,
  pairsVolume: state => state.pairsVolume?.map(item => ({
    date: item.date,
    pairs: item.pairs.map(pair => {
      return {
        name: `${pair.from.symbol}-${pair.to.symbol} (${pair.from.network}-${pair.to.network})`,
        volume: pair.volume,
      }
    }),
  })),
  pairsQuantity: state => state.pairsQuantity?.map(item => ({
    date: item.date,
    pairs: item.pairs.map(pair => {
      return {
        name: `${pair.from.symbol}-${pair.to.symbol} (${pair.from.network}-${pair.to.network})`,
        quantity: pair.quantity,
      }
    }),
  })),
  deFiWalletsTypes: state => state.deFiWalletsTypes,
  usersMap: state => state.usersMap,
  productsFees: state => state.productsFees,
  productsFeesTypes: state => state.productsFeesTypes,
  holdInfo: state => state.holdInfo,
}

const actions = {
  fetchTotal({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/total' }).then(response => {
      commit('setTotal', response.data)

      return response
    })
  },

  fetchMap({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/map' }).then(response => {
      commit('setMap', response.data)

      return response
    })
  },

  fetchVolumeFeeSnapshot({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/volume-fee-snapshot' }).then(response => {
      commit('setVolumeFeeSnapshot', response.data)

      return response
    })
  },

  fetchVolumeFee({ commit }, { dateFrom = undefined, dateTo = undefined } = {}) {
    const requestQuery = {
      dateFrom,
      dateTo,
    }
    const params = cleanObj(requestQuery)
    return axios({ method: 'GET', url: '/dashboard/volume-fee', params }).then(response => {
      commit('setLineCharts', { data: response.data, type: lineChartsTypes.fee })

      return response
    })
  },

  fetchVolumeProducts({ commit }, { dateFrom = undefined, dateTo = undefined } = {}) {
    const requestQuery = {
      dateFrom,
      dateTo,
    }
    const params = cleanObj(requestQuery)
    return axios({ method: 'GET', url: '/dashboard/product-volume', params }).then(response => {
      commit('setLineCharts', { data: response.data, type: lineChartsTypes.products })

      return response
    })
  },
  fetchQuantityVolumeTransactions({ commit }, { from = undefined, to = undefined } = {}) {

    const requestQuery = {
      from: getStartOfTheDayFromTimestamp(from),
      to: getEndOfTheDayFromTimestamp(to),
    }
    const params = cleanObj(requestQuery)
    return axios({ method: 'GET', url: '/dashboard/swap/charts/volume-quantity', params }).then(response => {
      commit('setComboCharts', { data: response.data, type: comboChartsTypes.quantityVolume })
      return response
    })
  },

  fetchVolumeFeeTransactions({ commit }, { from = undefined, to = undefined } = {}) {
    const requestQuery = {
      from: getStartOfTheDayFromTimestamp(from),
      to: getEndOfTheDayFromTimestamp(to),
    }
    const params = cleanObj(requestQuery)
    return axios({ method: 'GET', url: '/dashboard/swap/charts/volume-fee', params }).then(response => {
      commit('setComboCharts', { data: response.data, type: comboChartsTypes.volumeFee })
      return response
    })
  },
  fetchStatsToday({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/swap/stats/min-max-avg/today' }).then(response => {
      commit('setStatsToday', response.data)

      return response
    })
  },
  fetchStatsTotal({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/swap/stats/min-max-avg/total' }).then(response => {
      commit('setStatsTotal', response.data)

      return response
    })
  },
  fetchVolumeQuantityToday({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/swap/stats/volume-quantity/today' }).then(response => {
      commit('setVolumeQuantityToday', response.data)

      return response
    })
  },
  fetchVolumeQuantityTotal({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/swap/stats/volume-quantity/total' }).then(response => {
      commit('setVolumeQuantityTotal', response.data)

      return response
    })
  },
  fetchPairsList({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/swap/charts/pairs-list' }).then(response => {
      commit('setPairsList', response.data)

      return response
    })
  },
  fetchPairsVolumeToday({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/swap/charts/pie/pair-volume/today' }).then(response => {
      commit('setPairsVolumeToday', response.data)

      return response
    })
  },
  fetchPairsVolumeTotal({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/swap/charts/pie/pair-volume/total' }).then(response => {
      commit('setPairsVolumeTotal', response.data)

      return response
    })
  },
  fetchPairsVolume({ commit, getters }, { dateTo = undefined, dateFrom = undefined } = {}) {
    const tsFrom = getStartOfTheDayFromTimestamp(dateFrom)
    const tsTo = getEndOfTheDayFromTimestamp(dateTo)

    const requestQuery = {
      from: tsFrom,
      to: tsTo,
      pairs: getters.pairsList.map(pair => pair.key),
    }
    const params = cleanObj(requestQuery)
    return axios({ method: 'GET', url: '/dashboard/swap/charts/pair-volume', params }).then(response => {
      commit('setPairsVolume', response.data)
      return response
    })
  },

  fetchPairsQuantity({ commit, getters}, { dateFrom = undefined, dateTo = undefined } = {}) {
    const tsFrom = getStartOfTheDayFromTimestamp(dateFrom)
    const tsTo = getEndOfTheDayFromTimestamp(dateTo)
    const requestQuery = {
      from: tsFrom,
      to: tsTo,
      pairs: getters.pairsList.map(pair => pair.key),
    }
    const params = cleanObj(requestQuery)
    return axios({ method: 'GET', url: '/dashboard/swap/charts/pair-quantity', params }).then(response => {
      commit('setPairsQuantity', response.data)

      return response
    })
  },

  fetchHoldInfo({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/hold-stats' }).then(response => {
      commit('setHoldInfo', response.data)

      return response
    })
  },

  fetchActiveUsers({ commit }, { from = undefined, to = undefined } = {}) {
    const requestQuery = {
      dateFrom: from,
      dateTo: to,
    }
    const params = cleanObj(requestQuery)
    return axios({ method: 'GET', url: '/dashboard/active-users', params }).then(response => {
      commit('setComboCharts', { data: response.data, type: comboChartsTypes.activeUsers })

      return response
    })
  },

  fetchDeFiWalletsTypes({ commit }) {
      return axios({ method: 'GET', url: '/dashboard/charts/pie/defi-wallet-types' }).then(response => {
      commit('setDeFiWalletsTypes', response.data)

      return response
    })
  },

  fetchUsersMap({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/counters/country-user' }).then(response => {
      commit('setUsersMap', response.data)

      return response
    })
  },

  fetchStatsFees({ commit }) {
    return axios({ method: 'GET', url: '/dashboard/fees/stats/total' }).then(response => {
      commit('setStatsFees', response.data)

      return response
    })
  },

  fetchProductsFees({ commit }, { dateFrom = undefined, dateTo = undefined } = {}) {
    const from = getStartOfTheDayFromTimestamp(dateFrom)
    const to = getEndOfTheDayFromTimestamp(dateTo)
    const requestQuery = {
      from,
      to,
      products: productsFeesTypes,
    }
    const params = cleanObj(requestQuery)
    return axios({ method: 'GET', url: '/dashboard/fees/charts/products', params }).then(response => {
      commit('setProductsFees', response.data)

      return response
    })
  }
}

const mutations = {
  setTotal(state, totalObject) {
    state.total = totalObject
  },

  setMap(state, map) {
    state.map = map.record
  },

  setVolumeFeeSnapshot(state, volumeFeeSnapshot) {
    state.volumeFeeSnapshot = volumeFeeSnapshot
  },

  setLineCharts(state, { data = [], type = lineChartsTypes.users } = {}) {
    state.lineCharts[type] = data
  },
  setComboCharts(state, { data = [], type = comboChartsTypes.quantityVolume } = {}) {
    state.comboCharts[type] = data
  },
  setStatsToday(state, stats) {
    state.statsToday = stats
  },
  setStatsTotal(state, stats) {
    state.statsTotal = stats
  },
  setVolumeQuantityToday(state, volumeQuantity) {
    state.volumeQuantityToday = volumeQuantity
  },
  setVolumeQuantityTotal(state, volumeQuantity) {
    state.volumeQuantityTotal = volumeQuantity
  },

  setPairsList(state, pairsList) {
    state.pairsList = pairsList
  },
  setPairsVolumeToday(state, pairsVolume) {
    state.pairsVolumeToday = pairsVolume
  },
  setPairsVolumeTotal(state, pairsVolume) {
    state.pairsVolumeTotal = pairsVolume
  },
  setPairsVolume(state, pairsVolume) {
    state.pairsVolume = pairsVolume
  },
  setPairsQuantity(state, pairsQuantity) {
    state.pairsQuantity = pairsQuantity
  },
  setDeFiWalletsTypes(state, wallets) {
    state.deFiWalletsTypes = wallets
  },
  setUsersMap(state, usersMap) {
    state.usersMap = usersMap
  },
  setStatsFees(state, stats) {
    state.statsFees = stats
  },
  setProductsFees(state, productsFees) {
    state.productsFees = productsFees
  },
  setHoldInfo(state, payload) {
    state.holdInfo = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
