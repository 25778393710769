import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

import VueCookies from 'vue-cookies'
import router from './router'
import store from './store'

import App from './App.vue'
import DecimalPlugin from '@/plugins/decimal'
import SocketPlugin from '@/plugins/ws'

import '@/plugins/axios'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'
import '@/libs/clipboard'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueCookies)
Vue.use(DecimalPlugin)
Vue.use(SocketPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// charts lib
Vue.use(VueApexCharts)

// import assets styles
require('@/assets/scss/style.scss')

// Получение и запись токена
const token = Vue.$cookies.get('token')
if (token) store.commit('auth/setToken', { token })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
